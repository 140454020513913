import {Observable, of, throwError as observableThrowError, concat} from 'rxjs';
import {catchError, map, shareReplay} from 'rxjs/operators';
import {Inject, Injectable, PLATFORM_ID} from '@angular/core';
import {Router} from '@angular/router';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import { DOCUMENT, isPlatformBrowser } from '@angular/common';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class HttpService {

  private csrf: string = '';
  private jwt: string = '';
  private domain: string = '';
  private _url: string = '';

  // private configService: ConfigService
  constructor(@Inject(DOCUMENT) private _doc: Document, 
              private http: HttpClient, private router: Router,
              @Inject(PLATFORM_ID) private platformId: Object ) {
    
    this.domain = environment.domain;
    this._url = this.domain + 'account/status';

  }

  getSystemStatus(): Observable<any> {
    const headers = new HttpHeaders().set('Authorization', 'Bearer ' + localStorage.getItem('id_token'));
    headers.append('X-XSRF-TOKEN', localStorage.getItem('XSRF-TOKEN')!);
    return this.intercept(this.http.get(this._url, {headers}).pipe(shareReplay(1), map(res => res)));
  }

  get(url:string): Observable<any> {
    let headers = new HttpHeaders().set('Content-Type', 'application/json;charset=UTF-8');
    return this.intercept(this.http.get(url, {headers}).pipe(shareReplay(1), map(res => res)));
  }

  post(url:string, data:any): Observable<any> {
    let headers = this.getHeaders();
    return this.intercept(this.http.post(url, data, {headers}).pipe(shareReplay(1), map(res => res)));
  }

  put(url:string, data:any): Observable<any> {
    let headers = this.getHeaders();
    console.log('headers', headers);
    return this.intercept(this.http.put(url, data, {headers}).pipe(shareReplay(1), map(res => res)));
  }

  delete(url:string): Observable<any> {
    let headers = this.getHeaders();
    return this.intercept(this.http.delete(url, {headers}).pipe(shareReplay(1), map(res => res)));
  }


  intercept(observable: Observable<any>) {
    return observable.pipe(shareReplay(1), catchError(err => this.handleErr(err)));
  }

  handleErr(err: any) {

    if (err.status === 401) {
      return this.unauthorised();

    } else if (err.status === 403) {
      return this.forbidden();
    } else {
      return observableThrowError(err);
    }
  }

  unauthorised(): Observable<any> {

    if (typeof localStorage !== 'undefined') {
      localStorage.clear();
    }

    this.router.navigate(['/']);
    return of(true);
  }

  forbidden(): Observable<any> {
    localStorage.clear();
    this.router.navigate(['/']);
    return of(true);
  }

  checkAuthorised(): void {
    /* if (!this.token.token.length)
     {
       this.router.navigate(['login']);
     }*/
  }

  getHeaders(): HttpHeaders{

    let xsrf = '';
    let jwt = '';

    if (isPlatformBrowser(this.platformId)) {
      //console.log('localStore');
      if(localStorage.getItem('XSRF-TOKEN')){
        xsrf = localStorage.getItem('XSRF-TOKEN') as string;
      }
      if(localStorage.getItem('id_token')) {
        jwt = localStorage.getItem('id_token') as string;
      }
    }


    return new HttpHeaders()
                .set('Content-Type', 'application/json;charset=UTF-8')
                .append('Authorization', jwt)
                .append('XSRF-TOKEN', xsrf);      
  }


}
