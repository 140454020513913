import { HttpClient, HttpParams } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Offer } from '../../model/offer';
import { Observable, map, shareReplay } from 'rxjs';
import { DOCUMENT, DatePipe } from '@angular/common';
import { HttpService } from '../http.service';
import { AmenityDTO } from '../../model/amentyDTO';
import { GuestDTO } from '../../model/guestDTO';
import { StatusDTO } from '../../model/statusDTO';
import { ConfirmationDTO } from '../../model/confirmationDTO';
import { environment } from '../../../environments/environment';


@Injectable({
  providedIn: 'root',
 
})

export class OfferService {
 
  private domain: string = '';
  private _url: string = '';


  constructor(
    @Inject(DOCUMENT) private _doc: Document, 
    private http: HttpClient, 
    private httpService: HttpService) {
 
      this.domain = environment.domain;
      this._url = this.domain + 'guestoffers/';
     }

  datepipe: DatePipe = new DatePipe('en-US');

  public getOfferAmenties(){

  }

  public getAllOfferAmenties(offer_alias_id:string, prop_id: string): Observable<AmenityDTO[]> {
    const url = this._url + 'amenities/' + offer_alias_id + '/' + prop_id
    return this.httpService.get(url).pipe(
      shareReplay(1),
      map(res => {

        let amenties = res;
        let result: AmenityDTO[] = [];

        for(const a of amenties){
          result.push({ ...a, icons: [...a.icons]});
        }
        return result;
      }) 
    );
  }

  public getGuestInfo(offer_alias_id:string, prop_id: string): Observable<GuestDTO> {
    const url = this._url + 'guest/' + offer_alias_id + '/' + prop_id
    return this.httpService.get(url).pipe(
      shareReplay(1),
      map(res => {
        let result: GuestDTO[] = [...res]
        return result[0];
      }) 
    );
  }

  public getOfferStatus(offer_alias_id:string, prop_id: string): Observable<StatusDTO> {
    const url = this._url + 'offerStatus/' + offer_alias_id + '/' + prop_id
    return this.httpService.get(url).pipe(
      shareReplay(1),
      map(res => {
        let result: StatusDTO = {...res}[0]
        return result;
      }) 
    );
  }

  public getConfrimationInfo(offer_alias_id:string, prop_id: string): Observable<ConfirmationDTO> {
    const url = this._url + 'offerConfirmation/' + offer_alias_id + '/' + prop_id
    return this.httpService.get(url).pipe(
      shareReplay(1),
      map(res => {
        let result: ConfirmationDTO = {...res}[0]
        return result;
      }) 
    );
  }

  public setOfferDeclined(
    prop_id: string, 
    offer_alias_id: string, 
    reason_id: number | undefined = undefined
    ) {
    const url = this._url +  'decline/' + offer_alias_id + '/' + prop_id;

    let parameters: any = {'offer_alias_id': offer_alias_id};
    if (reason_id) parameters['reasonId'] = reason_id;
    const body = JSON.stringify(parameters);
    return this.httpService.put(url, body);
  }

  public setOfferAccepted(ofr: Offer) {
    
    const url = this._url + 'accept/' + ofr.offer_alias_id + '/' + ofr.prop_id;
    
    const body = ofr.promo_id ?
      JSON.stringify({
        'offer_id': ofr.offer_id,
        'tag': ofr.tag,
        'tag_id': ofr.tag_id,
        'notes': ofr.notes,
        'conf_num': ofr.conf_num,
        'is_ovrd': ofr.is_ovrd,
        'ofr_ovrd_price': ofr.ofr_ovrd_price,
        'los': ofr.los,
        'ofr_origin': ofr.ofr_origin,
        'promo_id': ofr.promo_id,
        'flg_push_to_pms': ofr.flg_push_to_pms,
        'flg_addon': ofr.flg_addon
      })
      : JSON.stringify({
        'offer_id': ofr.offer_id,
        'tag': ofr.tag,
        'tag_id': ofr.tag_id,
        'notes': ofr.notes,
        'conf_num': ofr.conf_num,
        'is_ovrd': ofr.is_ovrd,
        'ofr_ovrd_price': ofr.ofr_ovrd_price,
        'los': ofr.los,
        'ofr_origin': ofr.ofr_origin,
        'flg_push_to_pms': ofr.flg_push_to_pms,
        'flg_addon': ofr.flg_addon
      });


    return this.httpService.put(url, body);
  }

  public setGuestEmail(offer_alias_id: string, prop_id: string, email:string) {    
    const url = this._url + 'updateGuestEmail/' + offer_alias_id + '/' + prop_id;
    const body = JSON.stringify({ 'email': email });  
    return this.httpService.put(url, body);
  }

  public createOfferFromAlias(alias_id: string, prop_id: string): Observable<any> {
    
    const url = this._url + 'offer/' + alias_id + '/' + prop_id;
    return this.httpService.post(url, {'':''}).pipe(
      shareReplay(1),
      map(res => {

        let offer = res['data'];
        let offers = offer['offers'];
        let result: Offer[] = [];
 
        if(res){
          for(let ofr of offers){
            result.push({
              ...ofr,
              // alias_id
              // offer_alias_id: offer['offer_alias_id'], 
              // offer_set_id: offer['offer_set_id'],
              // offer_id:  ofr['offer_id'],
              tenant_id: offer['tenant_id'],
              prop_id: offer['prop_id'],                  
              // prop_name: '',                  
              // promo_id: '', // flag for removal
              // tag: '', // flag for removal
              // tag_id: '', // flag for removal
              // notes: '', // flag for removal
              conf_num: offer['conf_num'],
              // is_ovrd: false, // flag for removal
              // ofr_ovrd_price: 0, // flag for removal
              ofr_origin: offer['ofr_origin'],
              // flg_push_to_pms: true, // flag for removal
              // flg_addon: ofr['flg_addon'], // flag for removal                                    
              // is_hot_deal: false,
              // is_popular: false,
              // upsell_room_category_id: ofr['upsell_room_category_id'], 
              los: offer['los'],              
              base_room_category_id: offer['base_room_category_id'], 
              // base_room_category_name: '', 
              // guest_fname: '',
              // guest_lname: '',
              dt_arrival: offer['dt_arrival'],
              // ofr_price: ofr['ofr_price'], 
              // bar_price: ofr['bar_price'], 
              // pct_discount: ofr['pct_discount'], 
              // display_order: ofr['display_order'], 
              // flg_default_offer: ofr['flg_default_offer'], 
              // flg_valid_upsell: ofr['flg_valid_upsell'], 
              // flg_downgrade: ofr['flg_downgrade'],  
              // flg_upsell_available: ofr['flg_upsell_available'],  
              // ts_expires: ofr['ts_expires']
              confirmation_type: 'E',
              prop_info: 'Property Info Placeholder',

            } as Offer)
          }
        }
        return result
      }) 
    );
  }

}
