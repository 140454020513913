import { Injectable, inject } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { SharedService } from './shared.service';

@Injectable({
  providedIn: 'root'
})
export class BusyService {

  busyRequestCount = 0;
  public sharedService!: SharedService;
  public isBusy: boolean = false;
  private bdColor:string = '';
  private color:string = '';
  constructor(private spinnerService: NgxSpinnerService) {

    this.sharedService = inject(SharedService);

    this.color = this.sharedService.getStyleValue('spinnerTheme','color');
    this.bdColor = this.sharedService.getStyleValue('bodyTheme','background-color');
   }

  busy() {
    this.isBusy = true;
    this.busyRequestCount++;

    this.spinnerService.show(undefined, {
      type: 'ball-grid-pulse',
      bdColor: this.bdColor,
      color: this.color
    });
  }

  idle() {
    this.busyRequestCount--;
    if(this.busyRequestCount <= 0) {
      this.isBusy = false;
      this.busyRequestCount = 0;
      this.spinnerService.hide();
    }
  }

  showSpinner(){
    this.spinnerService.show(undefined, {
      type: 'ball-grid-pulse',
      bdColor: this.bdColor,
      color: this.color
    });
  }

  hideSpinner(){
    this.spinnerService.hide();
  }

}
