import { CommonModule } from '@angular/common';
import { Component, OnInit, ViewChild } from '@angular/core';
import { MatCardModule } from '@angular/material/card';
import { NgxSpinnerModule } from 'ngx-spinner';
import { CodeInputModule } from 'angular-code-input';
import { SharedService } from '../../_services/shared.service';
import { BreakpointObserver } from '@angular/cdk/layout';
import { AuthService } from '../../_services/auth.service';
import { OfferService } from '../../_services/offer/offer.service';
import { ActivatedRoute, Router } from '@angular/router';
import Utils from '../../_utils/utils';
import { ResponsiveMode } from '../../enum/responsiveModes';

@Component({
  selector: 'app-offer-refresh-offer',
  standalone: true,
  imports: [
    CommonModule,
    MatCardModule,
    NgxSpinnerModule,
    CodeInputModule
  ],
  templateUrl: './offer-refresh-offer.component.html',
  styleUrl: './offer-refresh-offer.component.css'
})
export class OfferRefreshOfferComponent implements OnInit {


  private propertyId: string = '';
  private offerAliasId: string = '';
  viewMode: string = ResponsiveMode.horizontal;
  
  @ViewChild('refreshButton') button: any;

  ngOnInit(): void {
    this.propertyId = this.route.snapshot.paramMap.get('prop_id') ? this.route.snapshot.paramMap.get('prop_id') as string : '';
    this.offerAliasId = this.route.snapshot.paramMap.get('alias_id') ? this.route.snapshot.paramMap.get('alias_id') as string : '';   
    
  }

  constructor(public sharedService: SharedService,
    private responsive: BreakpointObserver,           
    private router: Router, 
    private route: ActivatedRoute) { 
}

navigateToOfferConfirmation(){
  this.router.navigate(['/offer/' + this.offerAliasId + '/' + this.propertyId], { relativeTo: this.route });
}

responsiveStyle(styleObj: {[key: string]: string}): {[key: string]: boolean} {   
  return Utils.responsiveStyle(this.viewMode, styleObj);
}

}
