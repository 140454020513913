import { AfterViewInit, Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { OfferTokenValidationComponent } from '../offer-token-validation/offer-token-validation.component';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { FormControl, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { concat, filter, fromEvent, map, merge, Observable } from 'rxjs';
import { ActivatedRoute, Router } from '@angular/router';
import { ResponsiveMode } from '../../enum/responsiveModes';
import {takeUntilDestroyed} from '@angular/core/rxjs-interop';
import { SharedService } from '../../_services/shared.service';
import { CommonModule } from '@angular/common';
import Utils from '../../_utils/utils';
import { OfferService } from '../../_services/offer/offer.service';
import { AuthService } from '../../_services/auth.service';




@Component({
  selector: 'app-offer-form-email',
  standalone: true,
  imports: [
    CommonModule,
    OfferTokenValidationComponent,
    MatFormFieldModule, 
    MatInputModule, 
    FormsModule, 
    ReactiveFormsModule
  ],
  templateUrl: './offer-form-email.component.html',
  styleUrl: './offer-form-email.component.css'
})

export class OfferFormEmailComponent implements OnInit, AfterViewInit {

  private propertyId: string = '';
  private offerAliasId: string = '';
  viewMode: string = ResponsiveMode.horizontal;
  
  email = new FormControl('', [Validators.required, Validators.email, Validators.pattern('^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$')]);
  emailCheck = new FormControl('', [Validators.required, Validators.email, Validators.pattern('^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$')]);
  errorMessage = '';
  errorEmailCheckMessage = '';

  @ViewChild('_emailCheck') _emailCheck!: ElementRef;
  @ViewChild('_email') _email!: ElementRef;

  emailEnter$!: Observable<KeyboardEvent>;
  emailCheckEnter$!: Observable<KeyboardEvent>;

  ngAfterViewInit() {
    this.emailEnter$ = fromEvent<KeyboardEvent>(this._email.nativeElement, 'keyup').pipe(
      filter(event => event.key === 'Enter'),
      filter(() => this.email.valid && this.emailCheck.valid)
    );

    this.emailCheckEnter$ = fromEvent<KeyboardEvent>(this._emailCheck.nativeElement, 'keyup').pipe(
      filter(event => event.key === 'Enter'),
      filter(() => this.email.valid && this.emailCheck.valid)
    );

    this.emailEnter$.subscribe(() => this.updateGuestEmail());
    this.emailCheckEnter$.subscribe(() => this.updateGuestEmail());
  }

  constructor(public sharedService: SharedService,
              public offerService: OfferService,
              public authService: AuthService,
              private router: Router, 
              private route: ActivatedRoute) {
                
    merge(this.email.statusChanges, this.email.valueChanges)
      .pipe(takeUntilDestroyed())
      .subscribe(() => this.updateErrorMessage());
  }

  ngOnInit(): void {
    this.propertyId = this.route.snapshot.paramMap.get('prop_id') ? this.route.snapshot.paramMap.get('prop_id') as string : '';
    this.offerAliasId = this.route.snapshot.paramMap.get('alias_id') ? this.route.snapshot.paramMap.get('alias_id') as string : '';    

  }

  updateErrorMessage() {
    if (this.email.hasError('required')) {
      this.errorMessage = 'You must enter a value';
    } else if (this.email.hasError('email')) {
      this.errorMessage = 'Not a valid email';
    } else {
      this.errorMessage = '';
    }
  }

  updateErrorMailCheckMessage(){

    if (this.emailCheck.hasError('required')) {
      this.errorEmailCheckMessage = 'You must enter a value';
    } else if (this.email.hasError('email')) {
      this.errorEmailCheckMessage = 'Not a valid email';
    } else if (this.email.value !== this.emailCheck.value && this.emailCheck.dirty) {
      this.errorEmailCheckMessage = 'Emails do not match.';
    } else {
      this.errorEmailCheckMessage = '';
    }

  }

  updateGuestEmail() {  

    if(!this.email.value 
      || !this.email.valid 
      || !this.emailCheck.valid
      || this.emailCheck.value !== this.email.value) { return; }
    
    let setGuestEmail$ = this.offerService.setGuestEmail(this.offerAliasId, this.propertyId, this.email.value);
    let requestUpgradeToken$ = this.authService.requestUpgradeToken(this.offerAliasId, this.propertyId); 
    let combined$ = concat(setGuestEmail$, requestUpgradeToken$);

    combined$.subscribe(res => {            
      this.navigateToOfferTokenValidation();
    });


  }

  navigateToOfferTokenValidation(){
    this.router.navigate(['/offerTokenValidation/' + this.offerAliasId + '/' + this.propertyId], { relativeTo: this.route });
  }

  responsiveStyle(styleObj: {[key: string]: string}): {[key: string]: boolean} {   
    return Utils.responsiveStyle(this.viewMode, styleObj);
  }
    
}


