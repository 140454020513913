<ngx-spinner [ngStyle]="this.sharedService.customStyle('spinnerTheme')" type="ball-grid-pulse" size = "medium" [fullScreen] = "true"><h3 [ngStyle]="this.sharedService.customStyle('spinnerTheme')">Loading...</h3></ngx-spinner>
<link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/4.7.0/css/font-awesome.min.css">
<ng-template #template>
    <app-offer-photo-gallery [offer]="this.sharedService.offer" [modalRef]="modalRef" [offers]="offers"></app-offer-photo-gallery>
</ng-template>
<div style="height: 100%; width: 98% !important; align-content: center; margin:0 auto !important;">
    <app-offer-card-greeting [offers]="this.offers"></app-offer-card-greeting>
    <form [hidden]="!viewMoreToggle || !offers" [ngClass]="this.responsiveStyle({ 'vertical': 'search-form-handset', 'horizontal': 'search-form',})">
        <div style="height: auto; width: auto;">            
            <input class="input-search" [ngStyle]="this.sharedService.customStyle('formTheme')" #searchInput placeholder="Ex: Room Type (Suite), Beds, Sqft, etc" value="">
        </div>
    </form>
    <mat-card style="min-height: 290px;" [ngClass]="this.responsiveStyle({ 'vertical': 'offer-card-handset', 'horizontal': 'offer-card',})"
              [ngStyle]="this.sharedService.customStyle('cardTheme')"
              *ngFor="let offer of offers;let i = index">
        <div [ngClass]="this.responsiveStyle({ 'vertical': 'vertical-layout', 'horizontal': 'horizontal-layout',})">            
            <div [hidden]="horizontalView" class="container card-header-container-handset" [ngStyle]="this.sharedService.customStyle('cardHeaderTheme')">                  
                <p class="card-header-handset card-title-handset">{{offer.amenities.upsell_room_name}}</p>          
            </div>                   
            <app-offer-image [offer]="offer" [index]="i" (click)="openModal(template, i)" ></app-offer-image>
            <div class="icon-grouping" [ngClass]="this.responsiveStyle({ 'vertical': 'icon-grouping-handset', 'horizontal': 'icon-grouping-desktop'})">  
                <app-offer-icon [offer]="offer" [viewMode]="viewMode" [offers]="offers" [viewMoreToggle]="viewMoreToggle" [index]="i"></app-offer-icon>
                <app-offer-content [offers]="offers" [offer]="offer" [index]="i" [viewMoreToggle]="viewMoreToggle" ></app-offer-content>
            </div>
        </div>
    </mat-card>
</div>
<div *ngIf="offers" [hidden]="!offers" style="width: 98% !important;" [ngClass]="this.responsiveStyle({ 'vertical': 'other-options-handset', 'horizontal': 'other-options-desktop'})">
    <p class="other-options-paragraph" [ngStyle]="this.sharedService.customStyle('txtTheme')">
        <span class="button-link" (click)="this.declineOffer()">NO THANKS</span>
        <span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span> 
        <span class="button-link" (click)="toggleSearch()" #toggleView>{{this.viewMoreOrLess()}} &nbsp; 
            <i *ngIf="!viewMoreToggle" class="fa fa-plus" style="color: #77b300; "></i>
            <i *ngIf="viewMoreToggle" class="fa fa-minus" style="color: #cc0000; "></i>
        </span>
        <!-- <span *ngIf="viewMoreToggle" class="button-link" (click)="toggleSearch()" #toggleView>VIEW LESS &nbsp; </span> -->
        <span *ngIf="!this.horizontalView" style="width: 20px;"></span>
    </p>
</div>    