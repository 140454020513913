
<div style="width: 100vw; height: 100vh;">
    <mat-card [ngStyle]="this.sharedService.customStyle('cardTheme')"
        style="                    
            /* vertical center */
            margin: 0;
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            /* vertical center */

            padding-left: 50px;
            padding-right: 50px;
            padding-bottom: 50px;
            padding-top: 50px;

            width: 25%;
            min-width: 380px !important;
            height: auto;
            max-height: 221 !important;
            font-size: large;
            text-align: center;"

            class="not-available-text"   
        >
        <p [ngStyle]="this.sharedService.customStyle('cardTheme')">Apologies, it appears this offer is no longer available. Please click refresh to view more offers.</p>        

        <br>

        <button class="button-request" #refreshButton 
        [ngStyle]="this.sharedService.customStyle('buttonRequestTheme')"
        [ngClass]="this.responsiveStyle({ 'vertical': 'card-action-handset', 'horizontal': 'card-action-desktop'})"
        style="                    
                /* vertical center */
                margin-left: auto;
                margin-right: auto;
                float: right;
                width: 120px !important;
                height: 40px;
                "
        > REFRESH
        </button>
    </mat-card>
</div>
