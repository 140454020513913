import { Component, OnInit } from '@angular/core';
import { SharedService } from '../../_services/shared.service';
import { CommonModule, CurrencyPipe } from '@angular/common';
import { MatCardModule } from '@angular/material/card';
import { OfferService } from '../../_services/offer/offer.service';
import { ActivatedRoute, Router } from '@angular/router';
import { StatusDTO } from '../../model/statusDTO';
import { Observable, catchError, concat, ignoreElements, map, of, shareReplay, takeLast } from 'rxjs';
import { BusyService } from '../../_services/busy.service';
import { OfferNotAvailableComponent } from '../offer-not-available/offer-not-available.component';
import { NgxSpinnerModule } from 'ngx-spinner';
import { OfferStatus } from '../../enum/offerStatus';
import {MatTableModule} from '@angular/material/table';


export interface OfferStatusElement {
  name: string;
  value: string;
}

@Component({
  selector: 'app-offer-status',
  standalone: true,
  imports: [CommonModule,
            MatCardModule,
            OfferNotAvailableComponent,
            NgxSpinnerModule,
            MatTableModule,
            CurrencyPipe],
  providers: [CurrencyPipe],
  templateUrl: './offer-status.component.html',
  styleUrl: './offer-status.component.css'
})
export class OfferStatusComponent implements OnInit {

  private propertyId: string = '';
  private offerAliasId: string = '';
  public offerStatus$!: Observable<OfferStatusElement[]>;
  public offerConfirmation$!: Observable<OfferStatusElement[]>;
  public offerStatusDTO!: StatusDTO;
  public offerErrors$: any;
  private isError: boolean = false;

  public displayedColumns: string[] = ['name', 'value'];
  public offerStatusData: OfferStatusElement[] = [];
  public offerStatusMessage: string = '';
  public offerStatus: string = '';
  public OfferStatus = OfferStatus;

  constructor(public sharedService: SharedService,
              private offerService: OfferService,
              private route: ActivatedRoute,
              private router: Router,
              private busyService: BusyService,
              private cp: CurrencyPipe) { 
  }

  public offerStatusTable: OfferStatusElement[] = [];

  ngOnInit(): void {

    this.offerStatusData = [];
    this.propertyId = this.route.snapshot.paramMap.get('prop_id') ? this.route.snapshot.paramMap.get('prop_id') as string : '';
    this.offerAliasId = this.route.snapshot.paramMap.get('alias_id') ? this.route.snapshot.paramMap.get('alias_id') as string : '';
    this.offerConfirmation$ = this.getConfrimationInfo(this.offerAliasId, this.propertyId, this.offerStatusData);
    let offerStatus$ = this.getOfferStatus(this.offerAliasId, this.propertyId, this.offerStatusData);

    this.offerStatus$ = concat(offerStatus$, this.offerConfirmation$)
      .pipe(
        shareReplay()
        ,takeLast(1)
        , map(res => {
          this.offerStatusData = res;
          return this.offerStatusData;
        })

      );

    this.offerErrors$ = this.offerStatus$.pipe(
                                          shareReplay(1),
                                          ignoreElements(),
                                          catchError((err) => {
                                            console.log(err);
                                            return of(err)})
                                        );

                                            
    // this.offerStatusData = [{name: 'Upgrade Offer Status:', value:'ACCEPTED'},
    //                         {name: 'Conf Number:', value:'ABC123456789-XYZ'},
    //                         {name: 'New Room Type:', value:'EXECUTIVE DIRECTOR LANIA OCEAN VIEW SUITE ULTRA LONG DESCRIPTION'},
    //                         {name: 'Nightly Upgrade Rate:', value: '1500'},
    //                         {name: 'Upgrade Offer Status:', value: '1500'}];

  }
  
  getOfferStatus(offerAliasId: string, propertyId: string, offerStatusData: OfferStatusElement[]): Observable<OfferStatusElement[]> {
    return this.offerStatus$ = this.offerService.getOfferStatus(offerAliasId, propertyId)
      .pipe(
        shareReplay(1),
        map(res => {
          this.offerStatusMessage = this.getStatusMessage(res.upgrade_status);
          this.offerStatus = res.upgrade_status;
          offerStatusData.push({name: 'Upgrade Offer Status:', value: res.upgrade_status});
          offerStatusData.push({name: 'Conf Number:', value: res.conf_num});                        
          return offerStatusData;
        })
      );
  }

  getConfrimationInfo(offerAliasId: string, propertyId: string, offerStatusData: OfferStatusElement[]): Observable<OfferStatusElement[]> {
    return this.offerConfirmation$ = this.offerService.getConfrimationInfo(offerAliasId, propertyId)
      .pipe(
          shareReplay(1),
          map(res => {
            offerStatusData.push({name: 'New Room Type:', value: res.room_category_name});
            offerStatusData.push({name: 'Nightly Upgrade Rate:', value: this.cp.transform(res.ofr_price, 'USD', 'symbol', '1.2-2') as string});
            offerStatusData.push({name: 'Total Upgrade Charge:', value: this.cp.transform((res.ofr_price * res.los), 'USD', 'symbol', '1.2-2') as string});
            return offerStatusData;
          })
        );
  }

  getStatusMessage(offerStatus: string){
    
    let accepted = OfferStatus.ACCEPTED as string;
    let pending = OfferStatus.PENDING as string;
    let rejected = OfferStatus.REJECTED as string;

    var responseTexts: { [id: string] : string; } = {};

    responseTexts[accepted] = 'Your request was Accepted.';
    responseTexts[pending] = 'Your request is still processing. Please check back later.';
    responseTexts[rejected] = 'Apologies, your request could not be fulfilled. Feel free to ask about upgrades upon checkin.';        

    let key = offerStatus as keyof typeof responseTexts;

    return responseTexts[key];
  }
}



